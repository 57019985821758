<template>
  <div class="chart-container h-unset">
    <div class="chart__heading">
      <div class="chart__heading-title">{{ $t('chart.heading.clients') }}</div>
      <div class="chart__heading-subtitle">{{ filterTitle }}</div>
      <b-dropdown
          variant="background"
          class="chart-options"
          toggle-class="text-decoration-none rounded-10 px-4 py-4 "
          menu-class="rounded-8  dropdown-menu-show"
          no-caret
          dropleft
      >
        <template v-slot:button-content>
          <vertical-dots-icon/>
        </template>

        <b-dropdown-item @click="()=>changeFilter(7,'days')">
          <span class="text-regular-14 text-font-secondary">
            {{ $t('chart.last_7_days') }}
          </span>
        </b-dropdown-item>

        <b-dropdown-item @click="()=>changeFilter(30,'days')">
          <span class="text-regular-14 text-font-secondary">
            {{ $t('chart.last_30_days') }}
          </span>
        </b-dropdown-item>

        <b-dropdown-item @click="()=>changeFilter(12,'months')">
          <span class="text-regular-14 text-font-secondary">
            {{ $t('chart.last_12_months') }}
          </span>
        </b-dropdown-item>
        <li role="menuitem" class="" style="width: 220px">

          <div>
            <span style="padding:0 12px">{{ $t('chart.range') }}</span>
            <app-date-picker
                @onUpdate="onDatePicked"
            />
          </div>
        </li>


      </b-dropdown>
    </div>
    <div class="chart__options">
      <div class="chart__options-tag"
           :class="item.type==selectedOption?'active':''"
           v-for="item in chartFilterOptions"
           :key="item.type"
           @click="changeOption(item.type)"
      >
        {{ item.name }}
      </div>
    </div>
    <v-chart class="chart" style="height: 370px;" :option="option" :autoresize="true"/>
  </div>
</template>

<script>

import AppDatePicker from "@/views/pages/reports/charts/appDatePicker.vue";

export default {
  components: {AppDatePicker},
  props: {
    data: Object
  },
  computed: {
    filterTitle() {
      if (this.filter) {
        const {value, type} = this.filter;
        return this.$i18n.t('chart.last_' + value + '_' + type);
      }

      return this.$i18n.t('chart.last_12_months');
    },


    option() {
      return {
        tooltip: {
          show: true,
          formatter: "{b} : {c} ({d}%)"
        },
        legend: {

          orient: 'horizontal',
          bottom: '10%',
          selectedMode: false,
          icon: 'circle',
          align: 'right',
          itemGap:20,

        },
        textStyle: {
          fontFamily: 'din-next'
        },
        color: ['#00c0f3', '#fa4798', '#fed029', '#7367f0', '#bac1cc', '#28c76f', '#e0e4ec',],

        series: [


          {
            name: this.$t('chart.heading.users'),
            type: 'pie',
            radius: ['45%', '75%'],
            avoidLabelOverlap: true,
            clockwise: false,
            startAngle: 90,
            bottom: '20%',
            emphasis: {
              scaleSize: 0,
              itemStyle: {
                shadowBlur: 0,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0,0)'
              },

            },
            labelLine: {
              show: true
            },

            // label: {
            //   // formatter: " {c}  \n {b} ",
            //   show: true,
            //   position: 'center',
            //   formatter: 'asdfasdf',
            //   fontSize: 16,
            //   fontWeight: 'bold'
            // },
            label: {
              formatter: '{per|{d}%}',
              position: 'inner',
              color: '#fff',

              rich: {

                per: {
                  color: '#fff',
                  padding: [0, 4],
                }
              }
            },
            data: this.data.series
          },
        ],
        media: [ // each rule of media query is defined here
          {
            query: {
              maxAspectRatio: 1
            },   // write rule here

          },
        ]
      }
    }
  },

  data() {
    return {
      filter: '',
      selectedOption: 'genders',
      chartFilterOptions: [
        {
          type: 'genders',
          name: this.$i18n.t('chart.options.genders')
        },
        {
          type: 'ages',
          name: this.$i18n.t('chart.options.ages')
        },
        {
          type: 'days',
          name: this.$i18n.t('chart.options.order_days')
        },

      ]
    }
  },
  methods: {
    onDatePicked(d) {
      this.$emit('changeFilter', {
        type: 'range',
        value:JSON.stringify(d)
      });
    },
    changeOption(type) {
      this.selectedOption = type
      this.$emit('changeOption', this.selectedOption);
    },
    changeFilter(value, type) {
      this.filter = {value, type};
      this.$emit('changeFilter', this.filter);
    }
  }
};
</script>

<style scoped>

</style>
