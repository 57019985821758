<template>
  <date-range-picker
      ref="picker"
      :max-date="maxDate"
      :opens="opens"
      :locale-data="localeData"
      :singleDatePicker="'range'"
      :ranges="false"
      v-model="dateRange"
      @update="updateValues"


  >
    <template style="min-width: 350px;">
      {{ startDate }} - {{ endDate }}
    </template>
  </date-range-picker>

</template>

<script>
import moment, {now} from "moment";
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

moment.locale('en')
export default {
  components: {
    DateRangePicker
  },
  props: {
    data: Object
  },
  data() {
    return {
      showDateRange: false,
      date: moment(),
      maxDate: moment(),
      dateRange: {
        startDate: moment().subtract(1, 'months'),
        endDate: moment(),
      },
      opens: 'left',
      singleDatePicker: false,


      range: [],

    }
  },
  computed: {

    localeData() {
      //get the current locale data
      var locale = this.$i18n.locale;
      if (locale == 'en') {
        return {
          direction: 'ltr',
          format: 'mm/dd/yyyy',
          separator: ' - ',
          applyLabel: 'Apply',
          cancelLabel: 'Cancel',
          weekLabel: 'W',
          customRangeLabel: 'Custom Range',
          daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
          monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          firstDay: 6
        }
      } else {
        return {
          direction: 'rtl',
          format: 'dd/mm/yyyy',
          separator: ' - ',
          applyLabel: 'تطبيق',
          cancelLabel: 'إلغاء',
          weekLabel: 'W',
          customRangeLabel: 'Custom Range',
          daysOfWeek: ['احد', 'اثنين', 'ثلاثاء', 'اربعاء', 'خميس', "جمعة", "سبت"],
          monthNames: ['يناير', 'فبراير', 'مارس', 'أبريل', 'مايو', "يونيو", "يوليو", "أغسطس", "سبتمبر", "أكتوبر", 'نوفمبر', "ديسمبر"],
          firstDay: 6
        }
      }
    },
    startDate() {
      //format the start date as yyyy-mm-dd
      return moment(this.dateRange.startDate || now(), 'YYYY-MM-DD').locale('en');
    },
    endDate() {
      //format the end date as yyyy-mm-dd
      return moment(this.dateRange.endDate || now(), 'YYYY-MM-DD').locale('en');
    },


  },

  methods: {
    updateValues({startDate, endDate}) {
      this.dateRange = {startDate, endDate};
      this.$emit('onUpdate', {
        startDate: startDate,
        endDate: endDate
      });
    },


  }
};
</script>

<style scoped>
</style>
